import { lazy, Suspense } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import MasterLayout from "@/metronic-dashboard/layout/MasterLayout";
import NotFound from "@/pages/NotFound";
import { isDotIr } from "@/utils/isDotIr";

const AccountPage = lazy(() => import("./accounts/AccountPage"));
const FinancialPage = lazy(() => import("./financial/FinancialPage"));
const SupportPage = lazy(() => import("./support/SupportPage"));
const ProductsPage = lazy(() => import("./products/ProductsPage"));
const SupportServices = lazy(() => import("./others/supportServices"));
const Donation = lazy(() => import("./others/Donation"));
const DonationPaypal = lazy(() => import("./others/DonationPaypal"));
const Consultation = lazy(() => import("./others/Consultation"));
const AboutUs = lazy(() => import("./others/AboutUs"));
const Terms = lazy(() => import("./others/Terms"));

const DashboardPage = () => {
  return (
    <MasterLayout>
      {" "}
      <Suspense>
        <Routes>
          <Route path="profile/*" element={<AccountPage />} />
          <Route path="financial/*" element={<FinancialPage />} />
          <Route path="support/*" element={<SupportPage />} />
          <Route path="products/*" element={<ProductsPage />} />
          <Route
            path="/donation"
            element={isDotIr ? <Donation /> : <DonationPaypal />}
          />
          <Route path="consultation" element={<Consultation />} />
          <Route path="support-services" element={<SupportServices />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="terms" element={<Terms />} />
          <Route path="*" element={<NotFound fromDashboard />} />
          <Route index element={<AccountPage />} />
        </Routes>
      </Suspense>
    </MasterLayout>
  );
};

export { DashboardPage };
