import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import axiosInstance from '@/utils/axiosInstance';
import { isDotIr } from '@/utils/isDotIr';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { convertPersianNumbersToEnglish } from '@/utils/numLangChanger';
import PasswordInput from "../Inputs/passwordInput";

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('فرمت ایمیل نادرست است')
        .min(3, 'حداقل ۳ کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن ایمیل الزامی است.'),
    password: Yup.string()
        .min(6, 'حداقل 6 کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن رمز عبور الزامی است.'),
    remember: Yup.boolean(),
});

const registrationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'حداقل ۲ کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن نام الزامی است.'),
    family: Yup.string()
        .min(2, 'حداقل ۲ کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن نام خانوادگی الزامی است.'),
    email: Yup.string()
        .email('فرمت ایمیل نادرست است')
        .min(3, 'حداقل ۳ کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن ایمیل الزامی است.'),
    mobile: Yup.string()
        .required('وارد کردن شماره موبایل الزامی است.')
    ,
    password: Yup.string()
        .min(6, 'حداقل 6 کاراکتر')
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن رمز عبور الزامی است.'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'رمز عبور با تکرار آن مطابقت ندارد.')
        .required('تکرار رمز عبور الزامی است.'),
    acceptTerms: Yup.boolean().oneOf([true], 'باید شرایط و ضوابط را بپذیرید.'),
});

const otpSchema = Yup.object().shape({
    otp: Yup.string()
        .length(6, 'کد تایید باید ۶ رقم باشد')
        .required('وارد کردن کد تایید الزامی است')
});


const ShoppingCartAuthModal = ({ isOpen, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(undefined);
    const [isLoginView, setIsLoginView] = useState(true);
    const [showOtpForm, setShowOtpForm] = useState(false);
    const [identifier, setIdentifier] = useState('');

    const saveAuth = (authData) => {
        if (authData) {
            localStorage.setItem('auth', JSON.stringify(authData));
        } else {
            localStorage.removeItem('auth');
        }
    };


    const saveTempToken = (authData) => {
        try {
            if (authData?.access_token) {
                localStorage.setItem('temp_token', authData.access_token);
                localStorage.setItem('temp_auth_data', JSON.stringify(authData));
                Cookies.set(
                    isDotIr ? 'innoghteh_temp_token' : 'innoghteh_temp_token_com',
                    authData.access_token,
                    { expires: 1 / 24 }
                );
            }
        } catch (error) {
            console.error('Error saving temporary token:', error);
        }
    };

    const activateToken = () => {
        try {
            const tempToken = localStorage.getItem('temp_token');
            const tempAuthData = localStorage.getItem('temp_auth_data');

            if (tempToken && tempAuthData) {
                const authData = JSON.parse(tempAuthData);
                Cookies.set(
                    isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com',
                    tempToken,
                    { expires: 1 }
                );
                localStorage.setItem('auth', tempAuthData);
                localStorage.removeItem('temp_token');
                localStorage.removeItem('temp_auth_data');
                Cookies.remove(isDotIr ? 'innoghteh_temp_token' : 'innoghteh_temp_token_com');
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error activating token:', error);
            return false;
        }
    };
    const otpFormik = useFormik({
        initialValues: { otp: '' },
        validationSchema: otpSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);
            try {
                const response = await axiosInstance.post('/checkotp', {
                    identifier: identifier,
                    otp: values.otp,
                });

                if (response.data.success) {
                    if (activateToken()) {
                        setShowOtpForm(false);
                        setIdentifier('');
                        setStatus(undefined);
                        onClose();
                        window.location.reload();
                    } else {
                        setStatus('خطا در فعال‌سازی حساب کاربری. لطفا دوباره تلاش کنید.');
                    }
                } else {
                    setStatus('کد تایید نادرست است. لطفا دوباره تلاش کنید.');
                }
            } catch (error) {
                setStatus('خطا در تایید کد. لطفا دوباره تلاش کنید.');
            } finally {
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    const loginFormik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: false,
        },
        validationSchema: loginSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);
            try {
                const response = await axiosInstance.post('/login', {
                    email: values.email,
                    password: values.password,
                    remember: values.remember ? 1 : 0,
                });

                saveAuth(null);
                Cookies.remove('innoghteh_access_token');
                Cookies.remove('innoghteh_access_token_com');

                const auth = response.data;
                saveAuth(auth);

                Cookies.set(
                    isDotIr ? 'innoghteh_access_token' : 'innoghteh_access_token_com',
                    auth.access_token,
                    { expires: values.remember ? 31 : 1 }
                );

                onClose();
                window.location.reload();
            } catch (error) {
                saveAuth(null);
                setStatus('اطلاعات ورود نادرست است!');
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    const registrationFormik = useFormik({
        initialValues: {
            name: '',
            family: '',
            email: '',
            mobile: '',
            country_code: '',
            password: '',
            confirmPassword: '',
            acceptTerms: false,
        },
        validationSchema: registrationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);
            try {
                let mobileNumber = convertPersianNumbersToEnglish(values.mobile);

                if (!isDotIr) {
                    mobileNumber = '00' + mobileNumber;
                }

                const response = await axiosInstance.post('/register', {
                    name: values.name,
                    family: values.family,
                    email: values.email,
                    mobile: mobileNumber,
                    country_code: isDotIr ? '+98' : values.country_code,
                    password: values.password,
                    ref_code: '',
                });

                saveTempToken(response.data);
                setIdentifier(values.email);
                setShowOtpForm(true);
                setStatus(undefined);
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    setStatus('با این ایمیل یا شماره موبایل قبلا ثبت‌نام صورت گرفته است.');
                } else {
                    setStatus('مشکلی پیش آمده است! لطفا بعدا دوباره تلاش کنید.');
                }
            } finally {
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        if (!isOpen) {
            setShowOtpForm(false);
            setIdentifier('');
            setStatus(undefined);
            setIsLoginView(true); // Reset to login view when modal closes
        }
    }, [isOpen]);
    if (!isOpen) return null;  // Add this check

    return (
        <div className="fixed inset-0 z-150 flex items-center justify-center">
            <div
                className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                onClick={onClose}
            />

            <div className="relative z-50 w-full max-w-[600px] bg-[#212121] rounded-lg shadow-xl m-4 max-h-[90vh] overflow-y-auto">
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-white opacity-70 hover:opacity-100 focus:outline-none"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span className="sr-only">Close</span>
                </button>

                <div className="p-6">
                    {!showOtpForm && (
                        <div className="flex gap-4 mb-6 border-b border-gray-700">
                            <button
                                className={`pb-2 px-4 text-lg font-semibold ${isLoginView ? 'text-[--orange-primary] border-b-2 border-[--orange-primary]' : 'text-white'}`}
                                onClick={() => {
                                    setIsLoginView(true);
                                    setStatus(undefined);
                                }}
                            >
                                ورود
                            </button>
                            <button
                                className={`pb-2 px-4 text-lg font-semibold ${!isLoginView ? 'text-[--orange-primary] border-b-2 border-[--orange-primary]' : 'text-white'}`}
                                onClick={() => {
                                    setIsLoginView(false);
                                    setStatus(undefined);
                                }}
                            >
                                ثبت‌نام
                            </button>
                        </div>
                    )}

                    {showOtpForm ? (
                        <form className='form w-100' onSubmit={otpFormik.handleSubmit} noValidate>
                            <div className='text-center mb-5'>
                                <h1 className='text-gray-900 fw-bolder mb-3 text-[30px]'>تایید کد ارسال شده به ایمیل </h1>
                                <div className='fw-semibold text-[19.5px] text-center text-white'>
                                    کد تایید ارسال شده به ایمیل خود را وارد کنید
                                </div>
                            </div>

                            {status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{status}</div>
                                </div>
                            )}

                            <div className='fv-row mb-8'>
                                <input
                                    type='text'
                                    placeholder='کد تایید'
                                    {...otpFormik.getFieldProps('otp')}
                                    className={clsx(
                                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg text-center',
                                        {
                                            'is-invalid': otpFormik.touched.otp && otpFormik.errors.otp,
                                        },
                                        {
                                            'is-valid': otpFormik.touched.otp && !otpFormik.errors.otp,
                                        }
                                    )}
                                />
                                {otpFormik.touched.otp && otpFormik.errors.otp && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{otpFormik.errors.otp}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <button
                                type='submit'
                                className='w-full py-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] rounded font-semibold h-[51px] text-white'
                                disabled={loading}
                            >
                                {!loading && <span className='indicator-label'>تایید</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        لطفاً صبر کنید...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </form>
                    ) : isLoginView ? (


                        <form className='form w-100' onSubmit={loginFormik.handleSubmit} noValidate>
                            {/* Login Form Content */}
                            {status && (
                                <div className='my-lg-5 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{status}</div>
                                </div>
                            )}

                            <div className='fv-row mb-5'>
                                <label className='form-label font-semibold !text-[21px] text-gray-900'>آدرس ایمیل</label>
                                <input
                                    {...loginFormik.getFieldProps('email')}
                                    className={clsx(
                                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                                        { 'is-invalid': loginFormik.touched.email && loginFormik.errors.email },
                                        { 'is-valid': loginFormik.touched.email && !loginFormik.errors.email }
                                    )}
                                    type='email'
                                    autoComplete='off'
                                />
                                {loginFormik.touched.email && loginFormik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{loginFormik.errors.email}</span>
                                    </div>
                                )}
                            </div>

                            <div className='fv-row mb-5'>
                                <label htmlFor='pass-input-password' className='form-label text-gray-900 font-semibold !text-[21px]'>رمز عبور</label>
                                <PasswordInput formik={loginFormik} name='password' inputClassName='!bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg' eyeIconColor='black'/>
                                {/* <input
                                    type='password'
                                    autoComplete='off'
                                    {...loginFormik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                                        { 'is-invalid': loginFormik.touched.password && loginFormik.errors.password },
                                        { 'is-valid': loginFormik.touched.password && !loginFormik.errors.password }
                                    )}
                                />
                                {loginFormik.touched.password && loginFormik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{loginFormik.errors.password}</span>
                                    </div>
                                )} */}
                            </div>

                            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-5'>
                                <label className='form-check form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input scale-75 !bg-transparent border border-white'
                                        type='checkbox'
                                        {...loginFormik.getFieldProps('remember')}
                                    />
                                    <span className='form-check-label fw-semibold text-gray-900 text-[19px] translate-x-1'>
                                        مرا به خاطر بسپار
                                    </span>
                                </label>
                            </div>

                            <button
                                type='submit'
                                className='w-full py-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] rounded font-semibold h-[51px] text-white'
                                disabled={loading}
                            >
                                {!loading && <span className='indicator-label'>ورود</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        لطفاً صبر کنید...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </form>
                    ) : (
                        <form className='form w-100' onSubmit={registrationFormik.handleSubmit} noValidate>
                            {/* Registration Form Content */}
                            {status && (
                                <div className='my-lg-5 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{status}</div>
                                </div>
                            )}

                            <div className='fv-row mb-5'>
                                <label className='form-label font-semibold !text-[21px] text-gray-900'>نام</label>
                                <input
                                    {...registrationFormik.getFieldProps('name')}
                                    className={clsx(
                                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                                        { 'is-invalid': registrationFormik.touched.name && registrationFormik.errors.name },
                                        { 'is-valid': registrationFormik.touched.name && !registrationFormik.errors.name }
                                    )}
                                    type='text'
                                    autoComplete='off'
                                />
                                {registrationFormik.touched.name && registrationFormik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{registrationFormik.errors.name}</span>
                                    </div>
                                )}
                            </div>

                            <div className='fv-row mb-5'>
                                <label className='form-label font-semibold !text-[21px] text-gray-900'>نام خانوادگی</label>
                                <input
                                    {...registrationFormik.getFieldProps('family')}
                                    className={clsx(
                                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                                        { 'is-invalid': registrationFormik.touched.family && registrationFormik.errors.family },
                                        { 'is-valid': registrationFormik.touched.family && !registrationFormik.errors.family }
                                    )}
                                    type='text'
                                    autoComplete='off'
                                />
                                {registrationFormik.touched.family && registrationFormik.errors.family && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{registrationFormik.errors.family}</span>
                                    </div>
                                )}
                            </div>

                            <div className='fv-row mb-5'>
                                <label className='form-label font-semibold !text-[21px] text-gray-900'>آدرس ایمیل</label>
                                <input
                                    {...registrationFormik.getFieldProps('email')}
                                    className={clsx(
                                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                                        { 'is-invalid': registrationFormik.touched.email && registrationFormik.errors.email },
                                        { 'is-valid': registrationFormik.touched.email && !registrationFormik.errors.email }
                                    )}
                                    type='email'
                                    autoComplete='off'
                                />
                                {registrationFormik.touched.email && registrationFormik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{registrationFormik.errors.email}</span>
                                    </div>
                                )}
                            </div>

                            <div className='fv-row mb-5'>
                                <label className='form-label font-semibold !text-[21px] text-gray-900'>شماره موبایل</label>
                                {isDotIr ? (
                                    <PhoneInput
                                        id="mobile-input"
                                        country='ir'
                                        value={registrationFormik.values.mobile}
                                        className='ltr'
                                        onChange={(value, country) => {
                                            registrationFormik.setFieldValue('mobile', value)
                                            registrationFormik.setFieldValue('country_code', '00' + country.dialCode)
                                        }}
                                        inputClass={clsx(
                                            'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg !w-full !h-[51px] !border-none',
                                            { 'is-invalid': registrationFormik.touched.mobile && registrationFormik.errors.mobile },
                                            { 'is-valid': registrationFormik.touched.mobile && !registrationFormik.errors.mobile }
                                        )}
                                        inputProps={{
                                            name: 'mobile',
                                            autoComplete: 'off',
                                            placeholder: ''
                                        }}
                                    />
                                ) : (
                                    <PhoneInput
                                        country='us'
                                        value={registrationFormik.values.mobile}
                                        className='ltr'
                                        onChange={(value, country) => {
                                            registrationFormik.setFieldValue('mobile', value);
                                            registrationFormik.setFieldValue('country_code', '00' + country.dialCode);
                                        }}
                                        inputClass={clsx(
                                            'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg !w-full !h-[51px] !border-none',
                                            { 'is-invalid': registrationFormik.touched.mobile && registrationFormik.errors.mobile },
                                            { 'is-valid': registrationFormik.touched.mobile && !registrationFormik.errors.mobile }
                                        )}
                                        inputProps={{
                                            autoComplete: 'off',
                                            placeholder: ''
                                        }}
                                    />
                                )}
                                {registrationFormik.touched.mobile && registrationFormik.errors.mobile && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{registrationFormik.errors.mobile}</span>
                                    </div>
                                )}
                            </div>

                            <div className='fv-row mb-5'>
                                <label htmlFor='pass-input-password' className='form-label text-gray-900 font-semibold !text-[21px]'>رمز عبور</label>
                                <PasswordInput formik={registrationFormik} name='password' inputClassName='!bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg' eyeIconColor='black'/>
                                {/* <input
                                    type='password'
                                    autoComplete='off'
                                    {...registrationFormik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                                        { 'is-invalid': registrationFormik.touched.password && registrationFormik.errors.password },
                                        { 'is-valid': registrationFormik.touched.password && !registrationFormik.errors.password }
                                    )}
                                />
                                {registrationFormik.touched.password && registrationFormik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{registrationFormik.errors.password}</span>
                                    </div>
                                )} */}
                            </div>

                            <div className='fv-row mb-5'>
                                <label htmlFor='pass-input-confirmPassword' className='form-label text-gray-900 font-semibold !text-[21px]'>تکرار رمز عبور</label>
                                <PasswordInput formik={registrationFormik} name='confirmPassword' inputClassName='!bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg' eyeIconColor='black'/>
                                {/* <input
                                    type='password'
                                    autoComplete='off'
                                    {...registrationFormik.getFieldProps('confirmPassword')}
                                    className={clsx(
                                        'form-control !bg-[#ddd] text-black !text-[22px] !py-2 !rounded-lg',
                                        { 'is-invalid': registrationFormik.touched.confirmPassword && registrationFormik.errors.confirmPassword },
                                        { 'is-valid': registrationFormik.touched.confirmPassword && !registrationFormik.errors.confirmPassword }
                                    )}
                                />
                                {registrationFormik.touched.confirmPassword && registrationFormik.errors.confirmPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{registrationFormik.errors.confirmPassword}</span>
                                    </div>
                                )} */}
                            </div>

                            <div className='fs-base fw-semibold mb-5'>
                                <label className='form-check form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input scale-75 !bg-transparent border border-white'
                                        type='checkbox'
                                        {...registrationFormik.getFieldProps('acceptTerms')}
                                    />
                                    <span className='form-check-label fw-semibold text-gray-900 text-[19px] translate-x-1'>
                                        <Link to='/terms' className='text-white' onClick={onClose}>شرایط و ضوابط</Link> را می‌پذیرم.
                                    </span>
                                </label>
                                {registrationFormik.touched.acceptTerms && registrationFormik.errors.acceptTerms && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{registrationFormik.errors.acceptTerms}</span>
                                    </div>
                                )}
                            </div>

                            <button
                                type='submit'
                                className='w-full py-2 bg-[--orange-primary] hover:bg-[--orange-primary-hover] rounded font-semibold h-[51px] text-white'
                                disabled={loading}
                            >
                                {!loading && <span className='indicator-label'>ثبت‌نام</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        لطفاً صبر کنید...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShoppingCartAuthModal;