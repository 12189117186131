import React, { useState } from "react";
import clsx from "clsx";
import { FiEye, FiEyeOff } from "react-icons/fi";

export default function PasswordInput(props) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <div className="position-relative w-100">
        <input
          id={`pass-input-${props.name}`}
          type={showPassword ? "text" : "password"}
          autoComplete="off"
          {...props.formik.getFieldProps(props.name)}
          className={clsx(
            "form-control pr-5 ltr",
            props.inputClassName,
            {
              "is-invalid":
                props.formik.touched[props.name] &&
                props.formik.errors[props.name],
            },
            {
              "is-valid":
                props.formik.touched[props.name] &&
                !props.formik.errors[props.name],
            }
          )}
          style={{
            paddingRight: "85px",
          }}
        />

        <span
          className={clsx(
            "position-absolute end-0 top-50 translate-middle-y me-3 d-flex align-items-center justify-content-center cursor-pointer",
            {
              "me-12":
                (props.formik.touched[props.name] &&
                  props.formik.errors[props.name]) ||
                (props.formik.touched[props.name] &&
                  !props.formik.errors[props.name]),
            }
          )}
          onMouseDown={(e) => {
            e.preventDefault();
            setShowPassword(!showPassword);
          }}
          style={{
            width: "30px",
            height: "30px",
          }}
        >
          {showPassword ? (
            <FiEyeOff size={22} color={props.eyeIconColor} />
          ) : (
            <FiEye size={22} color={props.eyeIconColor} />
          )}
        </span>
      </div>

      {props.formik.touched[props.name] && props.formik.errors[props.name] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{props.formik.errors[props.name]}</span>
          </div>
        </div>
      )}
    </>
  );
}
