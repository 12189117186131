import {
  FC,
  ReactNode,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { AsideDefault } from "./components/aside/AsideDefault";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { PageDataProvider } from "../helpers/layout/core/PageData";
import Cookies from "js-cookie";
import {
  UserProvider,
  UserContext,
} from "@/metronic-dashboard/context/UserContext";
import { IpInfoProvider } from "@/metronic-dashboard/context/IpInfoContext";
import { isDotIr } from "@/utils/isDotIr";

interface MasterLayoutProps {
  children?: ReactNode;
}

const MasterLayout: FC<MasterLayoutProps> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, loading } = useContext(UserContext);

  const toggleDrawer = useCallback(() => setIsDrawerOpen((prev) => !prev), []);
  const closeDrawer = useCallback(() => setIsDrawerOpen(false), []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 992);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.setAttribute("data-kt-drawer-aside", "on");
      document.body.setAttribute("data-kt-drawer", "on");
    } else {
      document.body.removeAttribute("data-kt-drawer-aside");
      document.body.removeAttribute("data-kt-drawer");
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    const tokenName = isDotIr
      ? "innoghteh_access_token"
      : "innoghteh_access_token_com";
    const token = Cookies.get(tokenName);

    if (!token) {
      navigate("/auth", { replace: true });
      return;
    }

    if (!loading && currentUser?.email) {
      if (currentUser.verified === 0 || !currentUser.mobile) {
        navigate("/dashboard/profile/overview", {
          state: { emailNotVerified: false },
        });
      }
    }
  }, [navigate, loading, currentUser]);

  useEffect(() => {
    closeDrawer();
  }, [location.pathname, closeDrawer]);

  return (
    <PageDataProvider>
      <IpInfoProvider>
        <UserProvider>
          <div
            id="dashboard"
            className={`page d-flex flex-row flex-column-fluid ${
              isDrawerOpen ? "drawer-on" : ""
            }`}
          >
            <AsideDefault isDrawerOpen={isDrawerOpen} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <HeaderWrapper toggleDrawer={toggleDrawer} />
              <div
                id="kt_content"
                className="content d-flex flex-column flex-column-fluid"
              >
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div
                    id="kt_content_container"
                    className="container !pb-[30px]"
                  >
                    {children || <Outlet />}{" "}
                  </div>
                </div>
              </div>
            </div>
            {isDrawerOpen && isMobile && (
              <div
                className="drawer-overlay"
                style={{ zIndex: 109 }}
                onClick={closeDrawer}
              ></div>
            )}
          </div>
        </UserProvider>
      </IpInfoProvider>
    </PageDataProvider>
  );
};

export default MasterLayout;
